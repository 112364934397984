@import url('https://fonts.googleapis.com/css?family=Roboto');

html,body {
	margin: 0px;
	padding: 0px;
	height: 100%;
	width: 100%;
	background-color: #454d6a;
	color: white;
	font-family: 'Roboto', sans-serif;
}

/***********
** Navbar **
***********/

nav {
	display: flex;
	flex-direction: column;
	float: left;
	background-color: #3a3e4b;
	height: 100%;
	width: 100px;
	position: fixed;
	bottom: 0;
	z-index: 999;
	
	img {
		width: 90%;
		height: 90%;
		margin: 5%;
	}

	a img {
		width: 60%;
		height: 60%;
	}

	.navSquare{
		position: relative;
		display: block;
		width: 100px;
		height: 100px;
		border-bottom: 1px solid #99AAB5;
		text-align: center;

		a {
			text-decoration: none;
			color: white;
			margin: 0px;
		}
		
		.monkeySpin {
			animation-name: spin;
			animation-duration: 1000ms;
			animation-iteration-count: infinite;
		}
		
		@keyframes spin {
			from {
				transform:rotate(0deg);
			}
			
			to {
				transform:rotate(360deg);
			}
		}
		
	}

	.navSquare:hover {
		background-color: #4d515d;
	}

	.noHover:hover {
		background-color: #3a3e4b;
	}

	.selected {
		background-color: #317ed4 !important;
	}
}


/**************
** Main view **
**************/
.container {
	display: flex;
	flex-direction: column;
	float: right;
	padding: 4%;
	width: 85%;
	margin-left: 100px;
	position: relative;

	.row {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

a {
	text-decoration: none;
	color: white;
	margin: 0px;
}

p, h4 {
	a {
		text-decoration: underline;
	}
}

.box {
	position: relative;
	width: 100%;
	padding: 20px;
	margin: 10px;
	background-color: rgb(82, 91, 122);
	
	// Thanks to Chris from CSS Tricks for this: https://css-tricks.com/notched-boxes/
	--notchSize: 40px; 
	clip-path: 
		polygon(
			0% var(--notchSize), 
			var(--notchSize) 0%, 
			0px 0%, 
			100% 0px, 
			100% calc(100% - var(--notchSize)), 
			calc(100% - var(--notchSize)) 100%, 
			0px 100%, 
			0% 0px
		);
	
	p {
		padding-left: 10px;
	}
}

.skills {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 10px;
	list-style-type: none;
	min-width: 150px;

	li:nth-child(odd){
		margin-top: 5px;
		width: 60%;
		float: left;
	}

	li:nth-child(even){
		width: 40%;
		float: right;
	}
}

.footnote {
	font-size: small;
	padding-left: 1%;
}

/*********
** Blog **
*********/

.blogImage {
	display: block;
	width: 50%;
	margin: 10px;
	float: right;
}

.blogImageInline {
	display: block;
	max-width: 50%;
	margin: 10px;
}

.blogBtn {
	background-color: #317ed4;
	border: none;
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;

	// Thanks to Chris from CSS Tricks for this: https://css-tricks.com/notched-boxes/
	--notchSize: 20px; 
	clip-path: 
		polygon(
			0% var(--notchSize), 
			var(--notchSize) 0%, 
			0px 0%, 
			100% 0px, 
			100% calc(100% - var(--notchSize)), 
			calc(100% - var(--notchSize)) 100%, 
			0px 100%, 
			0% 0px
		);
}

.disabled {
	cursor: default;
	background-color: #3a3e4b;
}

code {
	background-color: #404040;
	padding: 1px;
	padding-left: 5px;
	padding-right: 5px;
}

/******************
** Media Query's **
******************/


@media only screen and (max-width: 1024px) {
	nav {
		width: 70px;

		.navSquare{
			width: 70px;
			height: 70px;
			font-size: 80%;
		}
	}

	.container {
		margin-left: 70px;

		.row {
			flex-direction: column;
			width: 90%;
			
			.blogImage {
				display: block;
				float: none;
				width: 100%;
				height: 100%;
				margin: 10px;
			}
		 
			.blogImageInline {
				display: block;
				max-width: 100%;
				height: 100%;
				margin: 10px;
			}
		}
		
		.col {
			width: 90%;
		}
		
	}

	.footnote {
		font-size: small;
		padding: 3%;
	}
	
}

@media only screen and (max-width: 720px) {
	body {
		height: 100%;
		overflow: auto;
	}

	nav {
		flex-direction: row;
		height: 70px;
		width: 100%;
		position: sticky;
		float: bottom;

		.navSquare{
			width: 70px;
			height: 70px;
			font-size: 80%;
			border-right: 1px solid #99AAB5;
			border-bottom: none;
		}
	}

	.container {
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 10px;
		padding-bottom: 10px;
		width: 90%;

		.row {
			width: 100%;
		}
	}

	.box {
		position: relative;
		width: 90%;
		padding: 20px;
		margin: 10px;
		background-color: #4d5676;
	}

	.footnote {
		font-size: small;
		padding: 5%;
	}
}